
@font-face {
	font-family: 'BNR'; 
	src: url(../src/fonts/bnr.ttf); 
}

@font-face {
	font-family: 'Manrope-ExtraLight'; 
	src: url(../src/fonts/Manrope-ExtraLight.ttf); 
}

@font-face {
	font-family: 'Manrope-Light'; 
	src: url(../src/fonts/Manrope-Light.ttf); 
}

@font-face {
	font-family: 'Manrope-Regular'; 
	src: url(../src/fonts/Manrope-Regular.ttf); 
}

@font-face {
	font-family: 'Manrope-Medium'; 
	src: url(../src/fonts/Manrope-Medium.ttf); 
}

@font-face {
	font-family: 'Manrope-Semibold'; 
	src: url(../src/fonts/Manrope-SemiBold.ttf); 
}

@font-face {
	font-family: 'Manrope-Bold'; 
	src: url(../src/fonts/Manrope-Bold.ttf); 
}

@font-face {
	font-family: 'Manrope-Extrabold'; 
	src: url(../src/fonts/Manrope-ExtraBold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Light'; 
	src: url(../src/fonts/Montserrat-Light.ttf); 
}

@font-face {
	font-family: 'Montserrat-Regular'; 
	src: url(../src/fonts/Montserrat-Regular.ttf); 
}

@font-face {
	font-family: 'Montserrat-Medium'; 
	src: url(../src/fonts/Montserrat-Medium.ttf); 
}

@font-face {
	font-family: 'Montserrat-Semibold'; 
	src: url(../src/fonts/Montserrat-Semibold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Bold'; 
	src: url(../src/fonts/Montserrat-Bold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Extrabold'; 
	src: url(../src/fonts/Montserrat-Extrabold.ttf); 
}

@media print {
    html, body {
        border: 1px solid white;
        height: 99%;
        page-break-after: avoid;
        page-break-before: avoid;
        background: url(../src/img/bgprint.svg) no-repeat top left;
        background-size: cover;
    }

    body::before {
        display: block;
        content: "";
        height: 150px;
        width: 100%;
    }

    .print {
        margin: 20px;
    }

    .print * {
        page-break-before: auto;
        page-break-after: auto;
    }
}

*, *:hover, *:active, *:visited, *:focus {
    outline: none !important;
}

body, body * {
    font-family: "Montserrat-Light", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

body {
    width: 100% !important;
}

pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, с 1999 года*/
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    margin: unset;
 }

.logoheader {
    padding: 10px 8px 14px 8px;
    width: 100%;
}

.logo {
    height: 60px;
    background: url(../src/img/logo.svg) no-repeat center center;
    background-size: contain;
}

.logomenu {
    height: 100px;
    margin: 30px 8px 10px 8px;
    background: url(../src/img/logomenu.png) no-repeat center center;
    background-size: contain;
}

.authlogo {
    display: flex;
    height: 180px;
    width: 100%;
    position: relative;
    top: 30px;
    background: url(../src/img/authlogo.png) no-repeat center center;
    background-size: contain;
}

.datepickerfull {
    width: 100%;
}

.ant-menu {
    background: transparent;
    color: #ffffff;
}

.ant-menu, .ant-menu * {
    font-family: "Montserrat-SemiBold" !important;
    font-size: 15px;
}

.dividerwhite {
    border-top: 1px solid rgb(255, 255, 255);
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover, .ant-menu-item-selected {
    color: #ffffff;
    background: #ffffff1a;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
    background: rgb(230 230 255 / 2%);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff14;
}

.ant-input-affix-wrapper {
    border-radius: 50px !important;
}

.mobilevis {
    display: none;
}

.headerfull {
    background: #E25971;
    -webkit-box-shadow: 0px 4px 30px 0px rgb(126 126 126 / 20%);
    box-shadow: 0px 4px 30px 0px rgb(126 126 126 / 20%);
    height: 200px;
    width: 100%;
    top: -100px;
    position: fixed;
    display: none;
    align-items: flex-end;
    z-index: 10;
    padding-top: 100px;
}

.header_margin {
    display: none;
    height: 84px;
}

.revfull {
    min-height: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-menu-horizontal {
    display: flex;
    justify-content: center;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: #001a29;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #002438;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: transparent;
    border-radius: 30px;
    min-height: 200px;
}

.site-layout-background > .ant-row {
    padding-bottom: 40px;
}

.cardnone {
    background: none!important;
    box-shadow: none!important;
}

.bordershadow {
    padding: 30px;
    border-radius: 30px;
    background: #ffffff;
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 20%);
}

.ant-form-item:last-child {
    margin-bottom: 0;
}

.wrow {
    display: flex;
    flex-wrap: wrap;
}

.nomargin {
    margin:0 !important
}

.wcol {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    min-width: 300px;
    flex: 1 1 calc(100%/4 - 4rem);
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.wcol > .ant-badge {
    height: 100%;
}

.max400 {
    max-width: 400px !important;
    margin-left: auto;
    margin-right: auto;
}

.listanswer {
    padding: 2px 10px;
    background: #edf6ff;
    border-radius: 30px;
}

.colors {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    max-width: 150px;
}

.colors > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 calc(100%/4 - 2px);
    margin: 5px 0;
}

.maxtask:before{
    content:"";
    display:block;
    height:30px;
    position:relative;
    top:180px;
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(247, 247, 247, .75))
}

.maxtask::after {
    content: "";
    height: 100%;
    width: 100%;
    background: transparent;
    position: absolute;
    z-index: 2;
    padding: 20px;
    top: 0;
    left: 0;
}

.c1.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7 75%)}
.c2.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffc9c5 75%)}
.c3.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffeaad 75%)}
.c4.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fffbcb 75%)}
.c5.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e4ffc4 75%)}
.c6.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d7fff6 75%)}
.c7.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d8f8ff 75%)}
.c8.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #c7dcff 75%)}
.c9.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ead2ff 75%)}
.c10.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffe1f1 75%)}
.c11.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffedd8 75%)}
.c12.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e8eaed 75%)}

.ant-layout, .ant-layout * {
    overflow: visible;
}

.lasttask {
    padding: 0 20px 20px 20px;
    margin-top: auto;
    margin-bottom: 0 !important;
}

.maxtask {
    max-height: 200px;
    overflow: hidden;
    height: 100%;
}

.floattask {
    display: flex;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 100;
    cursor: pointer;
    width: 60px;
    height: 60px;
}

.floattask > span {
    
}

.floattask > span:hover {
    transform: scale(1.05);
}

.icon_first {
    top: 0;
    left: 0;
    position: absolute;
    width: 60px;
    height: 60px;
    background: url(/src/img/icons/cat.png) no-repeat center center;
    background-size: cover;
}

.theme {
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #444444;
}

.taskheader {
    padding: 20px 0;
    display: flex;
}

.taskheader * {
    font-family: "Montserrat-SemiBold" !important;
}

.tasktop {
    z-index: 100;
    margin-left: auto;
}

.elementlock ~ * {
    z-index: 3;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: #fafafa8f !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #ffffffba !important;
}

.ant-tabs-tab {
    border-radius: 20px 20px 5px 5px !important;
}

.cardlist {
    align-items: stretch;
}

.cardlist .ant-card {
    height: 100%;
}

.ant-card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.15);
    -moz-box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.15);
    box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.15);
    background: rgba(255, 255, 255, .95);
}

.cardlist > * {
    width: 250px;
}

.cardlist .ant-card-actions {
    margin-top: auto;
}

.ant-table {
    background: transparent;
}

.elementlock > * {
    padding-left: 20px;
    padding-right: 20px;
}

.taskhide {
    opacity: .8;
    padding: 0;
    cursor: pointer;
    transition: all .3s ease;
    min-height: 200px;
}

.taskhide:first-of-type:last-child {
    max-width: 500px;
}

.ant-dropdown-menu-item:first-of-type {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.ant-dropdown-menu-item:last-of-type {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.ant-dropdown-menu {
    border-radius: 15px;
    padding: 0;
}

.taskhide:nth-child(4n+1):last-child {
    max-width: 500px;
}

.ant-card-meta-title {
    white-space: pre-wrap;
}

.ant-image-img {
    object-fit: contain;
}

.ant-btn-primary {
    border-radius: 30px;
}

.ant-btn-dashed {
    border-radius: 30px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float: none;
    text-align: center;
}

.ant-pagination {
    text-align: center;
}

.filelist .ant-col, .filelist .ant-col, .filelist .ant-col > .ant-list-item, .filelist .ant-col > .ant-list-item > .ant-card {
    height: 100%;
}

.filelist .ant-row {
    justify-content: center;
    align-items: stretch;
}

.badgemenu {
    margin-right: 5px;
}

.filelist .ant-row > div {
    margin-bottom: 20px;
}

.animdown > div {
    animation: carding 1s ease;
}

.w100 > .ant-space-item:nth-child(2) {
    width: 100%;
}

.taskhide:hover {
    -webkit-box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    transform: scale(1.01);
}

.ant-popover-inner {
    border-radius: 15px;
}

.ant-alert {
    border-radius: 20px !important;
}

.fcenter {
    text-align: center;
    display: flex;
    justify-content: center;
}

.fright {
    text-align: center;
    display: flex;
    justify-content: flex-end;
}

.authp {
    margin-bottom: 20px;
}

.authp u {
    font-size: 12px;
    cursor: pointer;
    text-decoration-color: #8a8a8a;
    color: #8a8a8a;
}

.filters {
    opacity: 0;
}

.table-padding {
    padding: 0 0 30px 0;
    border-radius: 30px;
    overflow: hidden;
}

.ant-table-header {
    border-radius: 30px;
    margin-bottom: 10px;
}

.table-padding .ant-table-thead > tr {
    background: rgb(121,144,234);
    background: url(./img/paw.svg), #6b98e7;
    background-size: cover !important;
}

.table-padding .ant-table-thead > tr > th {
    background: unset;
    color: rgba(255, 255, 255, 0.85);
    font-family: 'Manrope-Bold' !important;
    font-size: 1.5em;
}

.table-padding .ant-pagination {
    margin: 16px;
}

.table-padding .ant-table-thead {
    -webkit-box-shadow: 0px 0px 30px 0px rgb(126 126 126 / 20%);
    box-shadow: 0px 0px 30px 0px rgb(126 126 126 / 20%);
}

.shadowscreen {
    -webkit-animation: shadowscreen 3s infinite ease-in-out;
    animation: shadowscreen 3s infinite ease-in-out;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 20%);
    background: rgba(247, 247, 247, .95);
    border-radius: 30px;
    text-align: left;
}

.hoverlist .ant-list-item {
    transition: all .3s ease;
    cursor: pointer;
}

.hoverlist .ant-list-item:hover {
    transform: translateY(5px);
}

.modal-mini {
    max-width: calc(100vw - 10%);
}

.modal-mini .ant-modal-header {
    display: none;
}

.modal-mini > .ant-modal-content {
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 30%);
    background: url(../src/img/modal_back.png) no-repeat, rgb(222,236,245);
    background: url(../src/img/modal_back.png) no-repeat, linear-gradient(0deg, rgba(222,236,245,1) 0%, rgba(254,255,255,1) 40%, rgba(254,255,255,1) 60%, rgba(225,247,250,1) 100%);
    background-position-x: right;
}

.modal-mini.bg_chester > .ant-modal-content {
    background: url(../src/img/icons//icon_chester.svg) no-repeat, #ffffff;
    background-position-x: right;
    background-position-y: bottom;
}

.modal-mini .ant-form-item-label > label {
    color: #355b7d !important;
    font-family: 'Montserrat-Semibold' !important;
}

.modal-mini-header {
    padding-top: 20px;
    text-align: center;
}

.modal-mini-body {
    padding: 24px;
}

.modal-mini  .ant-modal-body {
    padding: 0;
}

.modal-mini-header::after {
    content: "";
    display: block;
    height: 1px;
    background: #97bee1;
    margin-bottom: 30px;
}

.modal-mini-header-title {
    color: #355b7d !important;
    font-family: 'Montserrat-Semibold' !important;
    font-size: 16px;
    margin: 10px 0 10px 0;
}

.modal-mini-header-title.title-big {
    font-size: 22px;
}

.modal-mini .ant-list-item strong {
    color: #355b7d
}

.modal-mini .ant-input-affix-wrapper, .modal-mini .ant-input-affix-wrapper, .modal-mini .ant-picker, .modal-mini .ant-select:not(.ant-select-customize-input) .ant-select-selector, .modal-mini .ant-form-item-control-input-content > .ant-input, .modal-mini textarea.ant-input {
    border: 1px solid #97bee1 !important;
    -webkit-box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
    -moz-box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
    box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
}

@keyframes shadowscreen {
    0% {
        -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    }
    50% {
        -webkit-box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
    }
    100% {
        -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    }
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}

.authform {
    justify-content: center;
}

.ant-avatar > img, .ant-avatar .ant-image-img {
    border-radius: 50%;
}

.authpage {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadpage {
    justify-content: center;
}

.bg-auth {
    background: url(../src/img/bg.jpg) fixed no-repeat top center !important;
    background-size: cover !important;
}

.buttmar > button {
    margin-right: 8px;
    margin-bottom: 12px;
}

.filtersvisible {
    position: absolute;
    opacity: 0;
    top: -1000px;
    width: 0;
    height: 0;
}

.filtersmargin {
    margin-right: 16px;
}

.lglobal, .loadpage {
    
}

.lglobal::before {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background: url(/src/img/bgfull.jpg) no-repeat top,#badae9;
    background-size: cover;
    background-attachment: fixed;
}

.lglobal > .ant-layout-content {
    /* display: flex;
    flex-direction: column; */
}

.lglobal > .ant-layout-content > .site-layout-background {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 24px;
}

.ptr_global {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.refresh-view {
    flex-grow: 1;
}

body::-webkit-scrollbar {
    width: 0px !important;
}

.serv-left {
    margin-right: 10px;
    display: flex;
    text-align: left;
}

.gutter-row {
    text-align: left;
}

.ant-drawer-content {
    background-color: #9FC7DE;
    padding-top: 130px;
}

.ant-drawer-content-wrapper {
    will-change: transform;
}

.ant-drawer-left, .ant-drawer-right {
    top: -100px !important;
    height: calc(100% + 100px) !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    padding: 0 16px 0 32px;
}

.ant-layout-sider {
    backdrop-filter: blur(2px);
    background-color: #ffffff21;
    color: #ffffff;
    border-right: 1px solid #ffffff;
}

.inputtrans * {
    color: #ffffff;
    background: transparent;
    background-color: transparent !important;
}

.whitebut * {
    border-color: #ffffff !important;
}

.whitebut .ant-btn:hover, .ant-btn:focus {
    color: #000000;
}

.inputtrans .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #ffffff;
}

.inputtrans .ant-input-affix-wrapper {
    border: 1px solid #ffffff;
}

.inputtrans .ant-btn {
    border-color: #ffffff;
    width: 22px;
    height: 22px;
    min-width: 22px;
}

.bnone {
    border: transparent !important;
}

.ant-drawer-body {
    padding: 0;
}

.menu_float {
    height: 60px;
    position: absolute;
    display: flex;
    align-items: center;
}

.menubutton {
    position: absolute;
    display: flex;
    left: 0;
    left: 22px;
    font-size: 18px;
    width: 40px;
    height: 40px;
    box-shadow: 5px 0 5px 0 rgb(0 0 0 / 15%);
    border-radius: 8px;
    background: #ffffff;
    color: #a36060;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.tworow {
    display: flex;
    flex-wrap: wrap;
}

.tworow > div {
    width: 50%;
    min-width: 50%;
}

.ant-table-cell-scrollbar {
    box-shadow: unset;
}

.global_app_content {
    margin-left: 276px;
}

.menu_pc {
    width: auto !important;
}

@media screen and (max-width: 1300px) {

    .leftmenu {
        display: none;
    }
}

@media screen and (max-width: 1200px) {

    .headerfull {
        display: flex;
    }
    .header_margin {
        display: block;
    }
    .global_app_content {
        margin-left: 0;
    }
    .menu_pc {
        display: none !important;
    }
}

@media screen and (max-width: 1100px) {

    .print_display {
        display: none;
    }

    .table-padding .ant-table-thead > tr > th {
        font-size: 1.2em;
    }

    .tworow {
        flex-direction: column;
    }

    .tworow > div {
        width: 100%;
        margin-bottom: 50px;
    }

    .tworow > div:last-of-type {
        margin-bottom: unset;
    }

    .filtershidden {
        display: none;
    }
    .filtersvisible {
        position: unset;
        opacity: 1;
        top: unset;
        width: unset;
        height: unset;
    }
    .filtersmargin {
        margin-right: 0;
    }
}

@media screen and (max-width: 1000px) {

    .rbc-toolbar-label {
        font-size: 16px;
        padding-right: unset;
    }
}

@media screen and (max-width: 700px) {


    .profile_full > .ant-result-info, .coldesc {
        padding: 48px 20px !important;
    }

    .table-padding .ant-table-thead > tr > th {
        font-size: 1em;
    }

    .cardlist {
        width: 100%;
    }

    .cardlist > * {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {

    .wcol:last-child:first-child {
        max-width: 100%;
    }
    .wcol {
        margin: 20px 0;
    }
    
    .bg-auth {
        background: url(../src/img/bgm.jpg) fixed no-repeat top center !important;
        background-size: cover !important;
    }

    
    
}

@media screen and (max-width: 600px) {

    .usercard {
        flex-direction: column !important;
    }

    .usercard > div:first-child {
        display: flex;
        justify-content: center;
    }

    .usercard > div {
        margin: 10px 0 !important;
    }
    
}

@media screen and (max-width: 500px) {
    
    .floattask > span {
        width: 60px;
        height: 60px;
    }

    .logo {
        height: 40px;
    } 

    .menu_float {
        height: 40px;
    }

    .wcol {
        min-width: 100%;
    }
}

@media screen and (max-width: 370px) {
    .row_min_vertical {
        flex-direction: column;
    }

    .row_min_vertical > div {
        margin-top: 10px;
    }
}

.gradient {
	color: transparent;    
	-webkit-background-clip: text;
	background-clip: text;
	background-image: linear-gradient(135deg, #0daaeb, #9054cb);
}

.auth_remember span {
    color: #FFFFFF !important;
    background-color: none !important;
}

.authpageblock {
    margin-top: 200px;
    padding: 24px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url(./img/form.png) no-repeat;
    background-size: cover;
}

.authtitle {
    font-family: 'Manrope-Bold' !important;
    color: #5C6468;
    font-size: 22px;
    text-align: center;
    margin: 1em;
}

.inauth {
    border-radius: 25px;
    height: 40px;
    color: #8a8a8a;
    background: #ffffff;
    border-color: #ffffff !important;
}

.inauth input:-webkit-autofill,
.inauth input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.inauth input::placeholder {
    color: #8a8a8a;
  }

.inauth input {
    caret-color: #5479a1;
    color: #8a8a8a !important;
}

.authicon {
    background: #5479a1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 38px;
}

.inauths {
    border-radius: 25px;
    width: 130px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Слой_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 300 150' style='enable-background:new 0 0 300 150;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23E94A60;%7D .st1%7Bfill:url(%23SVGID_1_);%7D%0A%3C/style%3E%3Crect class='st0' width='300' height='150'/%3E%3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='184.74' y1='-9.094947e-13' x2='184.74' y2='150' gradientTransform='matrix(1 0 0 -1 0 150)'%3E%3Cstop offset='0' style='stop-color:%23E97F8B'/%3E%3Cstop offset='1' style='stop-color:%23F5B0B3'/%3E%3C/linearGradient%3E%3Cpath class='st1' d='M300,0.08V150c-15.32,0-30.65,0-45.97,0c-28.54-17.38-59.8-38.65-92.17-64.56C125.27,56.16,69.48,0,69.48,0 S283.45,0.08,300,0.08z'/%3E%3C/svg%3E%0A");
    background-size: cover;
    border-color: #ff9dab !important;
    color: #ffffff !important;
}

.inauths:hover, .inauths:focus {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Слой_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 300 150' style='enable-background:new 0 0 300 150;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23E94A60;%7D .st1%7Bfill:url(%23SVGID_1_);%7D%0A%3C/style%3E%3Crect class='st0' width='300' height='150'/%3E%3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='184.74' y1='-9.094947e-13' x2='184.74' y2='150' gradientTransform='matrix(1 0 0 -1 0 150)'%3E%3Cstop offset='0' style='stop-color:%23E97F8B'/%3E%3Cstop offset='1' style='stop-color:%23F5B0B3'/%3E%3C/linearGradient%3E%3Cpath class='st1' d='M300,0.08V150c-15.32,0-30.65,0-45.97,0c-28.54-17.38-59.8-38.65-92.17-64.56C125.27,56.16,69.48,0,69.48,0 S283.45,0.08,300,0.08z'/%3E%3C/svg%3E%0A");
    background-size: cover;
    border-color: #ff9dab !important;
    color: #ffffff !important;
    transform: scale(.95);
}

.inreg {
    border-radius: 25px;
    min-width: 130px;
    background-image: url("data:image/svg+xml,%3Csvg id='Слой_1' data-name='Слой 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 300 150'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%234b56e8;%7D.cls-2%7Bfill:url(%23Безымянный_градиент_12);%7D%3C/style%3E%3ClinearGradient id='Безымянный_градиент_12' x1='184.74' y1='1024' x2='184.74' y2='874' gradientTransform='translate(0 -874)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%238080e8'/%3E%3Cstop offset='1' stop-color='%23b3b3f4'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EБез названия%3C/title%3E%3Crect class='cls-1' width='300' height='150'/%3E%3Cpath class='cls-2' d='M300,.08V150H254a879.74,879.74,0,0,1-92.17-64.56C125.27,56.16,69.48,0,69.48,0Z'/%3E%3C/svg%3E");
    background-size: cover;
    border-color: #9da9ff !important;
    color: #ffffff !important;
}

.inreg:hover, .inreg:focus {
    background-image: url("data:image/svg+xml,%3Csvg id='Слой_1' data-name='Слой 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 300 150'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%234b56e8;%7D.cls-2%7Bfill:url(%23Безымянный_градиент_12);%7D%3C/style%3E%3ClinearGradient id='Безымянный_градиент_12' x1='184.74' y1='1024' x2='184.74' y2='874' gradientTransform='translate(0 -874)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%238080e8'/%3E%3Cstop offset='1' stop-color='%23b3b3f4'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EБез названия%3C/title%3E%3Crect class='cls-1' width='300' height='150'/%3E%3Cpath class='cls-2' d='M300,.08V150H254a879.74,879.74,0,0,1-92.17-64.56C125.27,56.16,69.48,0,69.48,0Z'/%3E%3C/svg%3E");
    background-size: cover;
    border-color: #9d9dff !important;
    color: #ffffff !important;
    transform: scale(.95);
}

.tableimg {
    max-width: 50px;
}

.tableimg > img {
    width: 100%;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: rgba(212, 212, 212, 100%);
}

::selection {
    background: #dceaff!important;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-radius: 50px;
}

.ant-pagination-item {
    border-radius: 50px;
}

.mb0 {
    margin-bottom: 0 !important;
}

.ant-btn:hover, .ant-btn:focus {
    color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
}

.ant-pagination-item-active {
    border-color: rgb(254, 254, 255);
}

.ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover {
    border-color: rgb(0, 0, 0);
}

.ant-pagination-item-active:focus-visible a, .ant-pagination-item-active:hover a {
    color: rgb(0, 0, 0);
}

.ant-pagination-item-active a {
    color: #d9d9d9;
}

.ant-pagination-item:focus-visible, .ant-pagination-item:hover {
    border-color: rgb(254, 254, 255);
}

.ant-pagination-item:focus-visible a, .ant-pagination-item:hover a {
    color: rgb(254, 254, 255);
}

.ant-input:hover {
    border-color: rgb(0, 0, 0);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: rgb(0, 0, 0);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: rgb(0, 0, 0);
    box-shadow: 0 0 0 2px rgba(254, 254, 255, 0.2);
}

.ant-picker-range .ant-picker-active-bar {
    background: rgb(254, 254, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid rgb(254, 254, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #555555;
    color: rgb(254, 254, 255);
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after, .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    left: 6px;
    border-left: 1px dashed rgb(255, 255, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed rgb(255, 255, 255);
    border-bottom: 1px dashed rgb(255, 255, 255);
}

.ant-picker:hover, .ant-picker-focused {
    border-color: rgb(0, 0, 0);
}

.ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(254, 254, 255, 20%);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: rgb(203, 203, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: rgb(230, 230, 255);
}

.ant-picker-focused {
    border-color: rgb(0, 0, 0);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid rgb(0, 0, 0);
}

.ant-btn-dashed:active {
    color: rgb(0, 0, 0);
    background: #fff;
    border-color: rgb(0, 0, 0);
}

.ant-btn-dashed:hover, .ant-btn-dashed:focus {
    color: rgb(0, 0, 0);
    background: #fff;
    border-color: rgb(0, 0, 0);
}

.ant-input:focus, .ant-input-focused {
    border-color: rgb(0, 0, 0);
    -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: rgb(0, 0, 0);
}

.ant-input-affix-wrapper:hover {
    border-color: rgb(0, 0, 0);
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: rgb(0, 0, 0);
    -webkit-box-shadow: 0 0 0 2px rgba(254, 254, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(254, 254, 255, 0.2);
}

.ant-radio-checked .ant-radio-inner {
    border-color: rgb(181, 181, 255);
}

.ant-radio-checked::after {
    border: 1px solid rgb(181, 181, 255);
}

.ant-radio-inner::after {
    background-color: rgb(181, 181, 255);
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: rgb(181, 181, 255);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(181, 181, 255);
    border-color: rgb(181, 181, 255);
}

.ant-checkbox-checked::after {
    border: 1px solid rgb(181, 181, 255);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: rgb(181, 181, 255);
}

.bold {
    font-weight: 800;
}

.ant-input, .ant-input-affix-wrapper, .ant-input-number, .ant-modal-content, .ant-picker, .ant-popover-buttons button, .ant-select-multiple .ant-select-selector, .ant-select-single .ant-select-selector {
    border-radius: 30px!important;
}

.ant-modal-header {
    border-radius: 30px 30px 0 0;
}

.wowload {
    -webkit-animation: wowload 1s ease;
    animation: wowload 1s ease;
}

.ant-card-head-title {
    font-family: 'Manrope-Bold' !important;
    overflow: hidden !important;
    text-align: center;
}

.tooglecolor .ant-card-head {
    color: #ffffff;
    border-radius: 20px;
    background: rgb(121,144,234);
    background: url(./img/paw.svg), linear-gradient(0deg, rgba(121,144,234,1) 0%, rgba(122,146,237,1) 100%);
    background-size: cover !important;
    -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    border-bottom: none;
}

.tooglecolor > div > span:nth-of-type(4n+1) .ant-card-head {
    background: rgb(233,114,130);
    background: url(./img/paw.svg), linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 100%);
}

.tooglecolor > div > span:nth-of-type(4n+2) .ant-card-head {
    background: rgb(233,114,130);
    background: url(./img/paw.svg), linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 100%);
}

.tooglebackground .ant-card-body {
    background: url(./img/paw_blue.svg) no-repeat right bottom;
    background-size: contain !important;
}

.tooglebackground > div > span:nth-of-type(4n+1) .ant-card-body {
    background: url(./img/paw_pink.svg) no-repeat right bottom;
}

.tooglebackground > div > span:nth-of-type(4n+2) .ant-card-body {
    background: url(./img/paw_pink.svg) no-repeat right bottom;
}

.ant-card-meta-description {
    display: inline-block;
    overflow: hidden;
    overflow-wrap: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 50px;
    width: 100%;
}

@keyframes wowload {
    0% {
       opacity: 0;
    }
    100% {
       opacity: 1;
    }
}

.dempty * {
    font-weight: 600;
    color: #ffffff !important;
}

.service_extra::after {
    display: inline-block;
    content: ",";
    margin-right: 5px;
}

.service_extra:last-child::after {
    display: none;
}

.column900 {
    align-items: center;
}

.column900 > .ant-col {
    width: 100%;
    max-width: 900px;
    margin-bottom: 24px;
}

.column900 > .ant-col:last-of-type {
    width: 100%;
    max-width: 900px;
    margin-bottom: 0;
}

html {
    --antd-wave-shadow-color: rgb(254, 254, 255);
}

.spincenter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.ant-select-dropdown {
    border-radius: 15px;
    padding: 0;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgb(240 240 255);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
@media print {
    .tabsnoprint .ant-tabs-nav {
        display: none;
    }
    .noprint_listaction .ant-list-item-action {
        display: none;
    }
    .noprint {
        display: none !important;
    }
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #a2bbd16c;
}

.profile_full {
    overflow: hidden;
    color: #575757 !important;
}

.profile_full > .ant-result-info {
    background: rgb(233,114,130);
    background: url(./img/paw.svg), linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 90%);
    -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
}

.profile_full strong {
    font-family: 'Manrope-Bold' !important;
    font-weight: unset;
}

.coldesc {
    padding: 50px;
}

.profile_icon {
    display: flex;
    margin: auto;
    background: #ffffff;
    border-radius: 100px;
    width: fit-content;
    overflow: hidden;
    max-width: 90px;
    max-height: 90px;
}

.profile_icon > img {
    padding: 15px;
    width: 90px;
    height: 90px;
}

.icon_max {
    background: rgb(255, 255, 255);
}

.icon_max > img {
    width: 100%;
    height: 100%;
    padding: 0px;
}

.button_icon {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.button_icon > * {
    margin-right: 5px;
}

.text_icon {
    display: flex !important;
    align-items: center;
    justify-content: left;
}

.text_icon > * {
    margin-right: 5px;
}

.profile_title {
    font-family: 'Manrope-Bold' !important;
    color: #ffffff;
    line-height: 30px;
    font-size: 20px;
}

.profile_subtitle {
    display: flex;
    margin: auto;
    margin-top: 20px;
    font-family: 'Manrope-SemiBold' !important;
    color: #ffffff;
    max-width: 500px;
}

/* Календарь */

.event_full_list .ant-list-item {
    padding: 12px;
}

.event_body, .event_body * {
    color: #77838f;
    font-family: 'Montserrat-Medium' !important;
}

.event_title {
    color: #1e2022;
    font-family: 'Montserrat-Medium' !important;
}

.calendar_col {
    width: 100%;
    max-width: 700px;
    margin: auto;
    border: none !important;
    border-radius: 22px;
}

.calendar_title_events {
    color: #1e2022;
    font-family: 'Montserrat-Bold' !important;
    font-size: 16px;
    display: flex;
}

.calendar_info {
    margin-top: 30px !important;
    width: 100%;
    max-width: 700px;
    margin: auto;
}

.calendar_block {
    padding: 15px 10px;
    border-radius: 15px !important;
}

.rbc-toolbar .rbc-toolbar-label {
    padding: 0 20px !important;
    background-color: #ffffff58;
    border-radius: 8px;
    flex-grow: 0 !important;
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    /*background: url(./img/paw_gray.svg) no-repeat;*/
    font-family: 'Montserrat-Medium' !important;
    font-size: 15px;
    background-position: right top;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    padding-top: 20px;
}

.rbc-toolbar {
    background: rgb(233,114,130);
    background: url(./img/paw.svg), linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 100%);
    color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    background-size: cover !important;
}

.ant-popover-inner, .ant-popover-arrow-content {
    background: #f7d8cb;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: unset !important;
}

.rbc-show-more {
    display: none !important;
}

.rbc-row-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.rbc-row-content > .rbc-row:nth-child(2) {
    margin-top: auto;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
}

.rbc-row-content > .rbc-row:nth-child(2) .rbc-row-segment {
    display: flex;
    align-items: end;
    cursor: pointer;
}

.rbc-row-content > .rbc-row:not(:nth-child(1)):not(:nth-child(2)) {
    display: none !important;
}

.rbc-calendar {
    /* padding: 10px; */
}

.rbc-month-view {
    border: unset !important;
}

.rbc-header + .rbc-header {
    border-left: unset !important;
}

.rbc-header, .rbc-header * {
    border-bottom: unset !important;
    color: #77838f;
    font-family: 'Montserrat-SemiBold' !important;
    text-transform: capitalize;
    font-size: 15px;
}

.rbc-month-row:nth-child(2) > div:first-of-type > div {
    border-top: 1px solid #DDD;
}

.rbc-month-row > div:first-of-type > div:first-of-type {
    border-left: 1px solid #DDD;
}

.rbc-month-row > div:first-of-type > div:last-of-type {
    border-right: 1px solid #DDD;
}

.rbc-month-row:nth-child(2) > div:first-of-type > div:first-of-type {
    /* border-radius: 20px 0 0 0; */
    border-left: 1px solid #DDD;
}

.rbc-month-row:nth-child(2) > div:first-of-type > div:last-of-type {
    /* border-radius: 0 20px 0 0; */
    border-right: 1px solid #DDD;
}

.rbc-month-row:last-of-type > div:first-of-type > div:first-of-type {
    border-radius: 0 0 0 20px;
    border-left: 1px solid #DDD;
}

.rbc-month-row:last-of-type > div:first-of-type > div:last-of-type {
    border-radius: 0 0 20px 0;
    border-right: 1px solid #DDD;
}

.rbc-month-row:last-of-type > div:first-of-type > div {
    border-bottom: 1px solid #DDD;
}

.rbc-month-row + .rbc-month-row {
    border-top: unset;
}

.rbc-off-range-bg {
    background: unset !important;
}

.rbc-today {
    /* background-color: #e4ecf9 !important; */
    background-color: unset !important;
}

.toolbar-container-custom {
    background: rgb(233,114,130);
    background: url(./img/paw.svg), linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 100%);
    color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    background-size: cover !important;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-date {
    padding: 5px 20px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    min-width: 210px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
}

.label-date, .label-date * {
    text-transform: capitalize;
    font-family: "Montserrat-Bold" !important;
    font-size: 20px;
    color: #fff;
}

.toolbar-button-custom {
    font-size: 25px;
    height: 100%;
    cursor: pointer;
}

.toolbar-button-custom:hover {
    color: #ea4a5f;
    transition: all .2s ease;
}

.rbc-month-view, .rbc-header + .rbc-header, .rbc-day-bg + .rbc-day-bg, .rbc-month-row + .rbc-month-row, .rbc-agenda-view table.rbc-agenda-table, .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr, .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-color: #D2D1D1 !important;
}

.rbc-day-bg {
    border-top: 1px solid #ddd;
}

.rbc-month-row + .rbc-month-row {
    border-top: unset !important;
}

.rbc-toolbar button {
    border-color: transparent !important;
    margin-right: 3px !important;
}

.rbc-toolbar button {
    background-color: #edededbf !important;
    border-radius: 10px !important;
}

.rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0 !important;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-color: #ededed !important;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}

.rbc-toolbar button:hover {
    background-color: #edededbf !important;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}

.rbc-toolbar-label {
    text-transform: capitalize;
    font-family: "Montserrat-Bold" !important;
    font-size: 20px;
    padding-right: 196px;
}

.rbc-date-cell.rbc-now {
    color: #ea7a89;
    font-weight: 400;
}

.rbc-row-bg {
    /* z-index: 5; */
}

.calendar_hide {
    margin-left: auto;
    font-family: 'Montserrat-Medium' !important;
    color: #8d8d8d;
}

.button_circle {
    padding: 5px;
    border-radius: 50px;
    color: #ffffff;
    background: #007bff;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-custom {
    color: #fff;
    background-color: #6c757d;
    padding: 4px 16px;
    text-align: center;
    font-size: 16px;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 20px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease;
}

.button-custom:hover {
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 30%);
    transform: scale(1.02);
}

.custom-green {
    background: #28a745;
}

.custom-red-new {
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Слой_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 300 150' style='enable-background:new 0 0 300 150;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23E94A60;%7D .st1%7Bfill:url(%23SVGID_1_);%7D%0A%3C/style%3E%3Crect class='st0' width='300' height='150'/%3E%3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='184.74' y1='-9.094947e-13' x2='184.74' y2='150' gradientTransform='matrix(1 0 0 -1 0 150)'%3E%3Cstop offset='0' style='stop-color:%23E97F8B'/%3E%3Cstop offset='1' style='stop-color:%23F5B0B3'/%3E%3C/linearGradient%3E%3Cpath class='st1' d='M300,0.08V150c-15.32,0-30.65,0-45.97,0c-28.54-17.38-59.8-38.65-92.17-64.56C125.27,56.16,69.48,0,69.48,0 S283.45,0.08,300,0.08z'/%3E%3C/svg%3E%0A");
    background-size: cover;
    border-color: #ff9dab !important;
    color: #ffffff !important;
}

.service_price {
    padding: 2px 10px;
    border-radius: 30px;
    border: 1px solid #7b7b7b;
    color: #575757;
}

.button_blue {
    background: #6a98e7;
    color: #ffffff !important;
    border-color: #6a98e7 !important;
}

.button_blue:hover, .button_blue:active, .button_blue:focus {
    background: #5d89d7 !important;
}

.button_red {
    background: #EB7281;
    color: #ffffff !important;
    border-color: #EB7281 !important;
}

.button_red:hover, .button_red:active, .button_red:focus {
    background: #d5616f !important;
}

.button_gray {
    background: #909090;
    color: #ffffff !important;
    border-color: #909090 !important;
}

.button_gray:hover, .button_gray:active, .button_gray:focus {
    background: #888888 !important;
}

.button_green {
    background: #82eb72;
    color: #ffffff !important;
    border-color: #82eb72 !important;
}

.button_green:hover, .button_green:active, .button_green:focus {
    background: #6ed05f !important;
}

.button_normal{
    padding: 2px 20px;
    border-radius: 30px;
    border: 1px solid #7b7b7b;
    color: #575757;
    cursor: pointer;
    transition: all .2s ease;
}

.button_normal:hover {
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%);
    transform: scale(1.02);
}

.cal-badge {
    color: #fff;
    background-color: #6c757d;
    padding: .25em .4em;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50px;
    display: inline-block;
}

.cal-badge-open {
    background-color: #a4c8ff;
    color: #1f2b44;
}

.cal-badge-close {
    background-color: #A6CE43;
}

.cal-badge-danger {
    background-color: #F8485E;
}

.cal-badge-warning {
    background-color: #ffc107;
}

.pay_button {
    padding: 10px 5px;
    background: #f8f8f8;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    transition: all .2s ease;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
}

.pay_button:hover {
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
    -webkit-transform: scale(1.01) !important;
    -ms-transform: scale(1.01) !important;
    transform: scale(1.01) !important;
}

.pay_button > img {
    height: 30px;
    width: 150px;
    object-fit: contain;
}

.table-500 .ant-table-tbody {
    max-height: 500px;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #a2bbd16c;
}

/* Специалисты */
.doctors_list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.doctors_item {
    cursor: pointer;
    display: flex;
    width: 25%;
    max-width: 380px;
    padding: 10px;
    text-align: left;
}

.doctors_block {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    transition: all .3s ease;
    padding-bottom: 20px;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.doctors_block:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.doctors_photo {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top;
    transition: all .3s ease;
    user-select: none;
    -webkit-user-select: none;
    pointer-events: none;
}

.doctors_block > .card-content {
    padding: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.doctors_block h2 {
    font-family: 'Montserrat-Semibold' !important;
    padding-top: 10px;
    color: #f76270;
    font-size: 120%;
    line-height: 1.2;
}

.doctors_block span {
    font-size: 110%;
    color: #666;
}

.doctors_block p {
    margin: 20px 0 30px;
    color: #666;
    font-size: 100%;
}

.reception_time {
    margin-top: auto;
    padding-top: 20px;
}

.reception_time_title {
    font-weight: 600;
}

/* Просмотр специалиста */
.doctors_view {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.doctors_view_photo {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-width: calc(50% - 20px);
    border-radius: 15px 90px;
}

.doctors_view_profile {
    width: 100%;
    max-width: calc(50% - 20px);
}

.doctors_view_name {
    color: #f76270;
    margin-top: 20px;
    font-size: 25px;
    font-family: "Montserrat-Medium" !important;
}

.doctors_view_specialization {
    margin-top: 20px;
    font-family: "Montserrat-Medium" !important;
}

.doctors_view_about {
    text-align: justify;
    margin-top: 20px;
    font-family: "Montserrat-Regular" !important;
}

.doctors_view_reception_time {
    margin-top: 20px;
    font-family: "Montserrat-Regular" !important;
}

.doctors_view_reception_time_title {
    font-family: "Montserrat-Medium" !important;
}

/* Предложения */

.offers_list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.offers_item {
    cursor: pointer;
    display: flex;
    width: 25%;
    padding: 5px;
    text-align: left;
}

.offers_block {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    transition: all .3s ease;
    padding-bottom: 20px;
    overflow: hidden;
    width: 100%;
}

.offers_block:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.offers_photo {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: all .3s ease;
    user-select: none;
    -webkit-user-select: none;
    pointer-events: none;
}

.card-content {
    padding: 0 20px;
}

.offers_block h2 {
    font-family: 'Montserrat-Semibold' !important;
    padding-top: 10px;
    color: #f76270;
    font-size: 120%;
    line-height: 1.2;
}

.offers_block span {
    font-size: 110%;
    color: #666;
}

.offers_block p {
    margin: 20px 0 30px;
    color: #666;
    font-size: 100%;
}

.padding_panel {
    padding: 20px;
}

@media screen and (max-width: 1500px) {
    .doctors_item {
        width: 33.33%;
        max-width: unset;
    }
    .offers_item {
        width: 33.33%;
    }
}

@media screen and (max-width: 1150px) {
    .doctors_view_photo {
        margin: auto;
    }
    .doctors_view_profile {
        max-width: 100%;
    }
    .doctors_view_name {
        text-align: center;
    }
    .doctors_view_specialization {
        text-align: center;
    }
    .doctors_item {
        width: 50%;
    }
    .offers_item {
        width: 50%;
    }
    .padding_panel {
        padding: 10px;
    }
}

@media screen and (max-width: 700px) {
    .doctors_view_name {
        font-size: 18px;
    }
    .doctors_view_specialization {
        margin-top: 10px;
    }
    .doctors_view_about {
        margin-top: 10px;
    }
    .doctors_view_photo {
        border-radius: 8px 45px;
    }
    .doctors_item {
        width: 100%;
    }
    .offers_item {
        width: 100%;
    }
    .padding_panel {
        padding: 0px;
    }
}

.offer_content img {
    max-width: 100%;
    object-fit: contain;
    height: auto;
}

.offer_content ul>li {
    list-style-image: url(./img/li_wap.png);
}

.offer_content > .blocks p {
    text-indent: 20px;
}

.offer_title {
    text-align: center;
    font-size: 140%;
    font-family: 'Montserrat-Semibold' !important;
    color: #355b7d;
    margin-bottom: 20px;
}

.cashback_count {
    font-family: 'Montserrat-Bold' !important;
    color: #1e2022 !important;
    font-size: 20px;
    margin: auto;
    padding: 20px 0;
    text-align: center;
    width: fit-content;
}

.cashback_list .ant-list-item-meta-title * {
    font-family: 'Montserrat-Medium' !important;
    color: #8c8c8d !important;
}

.cashback_list .ant-list-item-meta-description {
    font-family: 'Montserrat-Medium' !important;
    color: #1e2022 !important;
}

.cashback_sum, .cashback_sum * {
    font-family: 'Montserrat-Bold' !important;
    color: #1e2022 !important;
    font-size: 20px;
}

.cashback_collapse {
    width: 100%;
    max-width: 600px;
    margin: auto;
    border-radius: 23px;
    background-color: #909090 !important;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 30px;
}

.cashback_collapse .ant-collapse-header, .cashback_collapse .ant-collapse-header * {
    color: #ffffff !important;
}

.cashback_collapse > .ant-collapse-item {
    border-bottom: none !important;
}

.cashback_collapse .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 23px;
}

.cashback_collapse > .ant-collapse-item:last-child, .cashback_collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 23px 23px;
}

.cashback_collapse .ant-collapse-header {
    display: flex;
}

.cashback_info_title {
    display: flex;
    align-items: center;
    order: 1;
}

.cashback_collapse .ant-collapse-arrow {
    order: 2;
    margin-left: auto;
    display: flex !important;
    align-items: center;
}

.cashback_info_title svg {
    margin-right: 5px;
}

.cons_title * {
    color: #575757;
    font-family: 'Montserrat-Medium' !important;
}

.cons_date {
    margin-left: auto;
}

.cons_date, .cons_date * {
    color: #575757;
    font-size: 10px;
    font-family: 'Montserrat-Regular' !important;
}

.cons_block .ant-card-body {
    padding: 4px 24px 24px 24px !important;
}

/* pull to refresh */

.downinfiniti, .upinfiniti {
    font-size: 20px;
    color: #6A98E7;
    margin: auto auto 20px auto;
    display: flex;
}

.container-prt {
    height: 50px;
    text-align: center;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
}

.spininfiniti {
    font-size: 20px;
    color: #6A98E7;
    animation: spininfiniti 0.5s infinite linear;
    margin: auto auto 20px auto;
    display: flex;
}

@keyframes spininfiniti {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.ptr-element {
	position: absolute;
	top: 80px;
	left: 0;
	width: 100%;
	color: #aaa;
	z-index: 0;
	text-align: center;
	height: 50px;
}

.ptr-element .genericon {
	opacity: .6;
	font-size: 34px;
	width: auto;
	height: auto;
	transition: all .25s ease;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	margin-top: 5px;
}
.ptr-refresh .ptr-element .genericon {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}
.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
	display: none;
}

.loading {
	display: inline-block;
	text-align: center;
	opacity: 1;
	margin: 12px 0 0 5px;
	display: none;
}
.ptr-loading .loading {
	display: block;
}

.loading span {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 10px;
	margin-right: 3px;
	-webkit-transform: scale(0.3);
	transform: scale(0.3);
	border-radius: 50%;
	-webkit-animation: ptr-loading 0.4s infinite alternate;
	animation: ptr-loading 0.4s infinite alternate;
}

.loading-ptr-1 {
	-webkit-animation-delay: 0;
	animation-delay: 0 !important;
}

.loading-ptr-2 {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s !important;
}

.loading-ptr-3 {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s !important;
}

@-webkit-keyframes ptr-loading {
	0% {
		-webkit-transform: translateY(0) scale(0.3);
		transform: translateY(0) scale(0.3);
		opacity: 0;
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		background-color: #333;
		opacity: 1;
	}
}

@keyframes ptr-loading {
	0% {
		-webkit-transform: translateY(0) scale(0.3);
		transform: translateY(0) scale(0.3);
		opacity: 0;
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		background-color: #333;
		opacity: 1;
	}
}

.ptr-loading .refresh-view, .ptr-reset .refresh-view,
.ptr-loading .ptr-element, .ptr-reset .ptr-element {
	transition: all .25s ease;
}

.ptr-reset .refresh-view {
	-webkit-transform: translate3d( 0, 0, 0 );
	transform: translate3d( 0, 0, 0 );
}

.ptr-loading .refresh-view {
	-webkit-transform: translate3d( 0, 30px, 0 );
	transform: translate3d( 0, 30px, 0 );
}

body:not(.ptr-loading) #container .ptr-element {
	transform: translate3d(0, -50px, 0);
}

.custom-left-menu {
    will-change: transform;
    transform: translateX(-300px);
    top: -130px;
    position: fixed;
    z-index: 100;
    width: auto;
    height: calc(100% + 130px);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    -webkit-transition: all 0.2s cubic-bezier(1, 0.1, 0, 0.9);
    transition: all 0.2s cubic-bezier(1, 0.1, 0, 0.9);
    overflow: hidden;
}

.custom-left-menu::-webkit-scrollbar {
    width: 0px !important;
}

.custom-left-menu.open {
    width: 100%;
    transform: translateX(0);
}

.custom-left-menu.open ~ .ant-layout {
    overflow: hidden;
}

.custom-left-menu .menu-mask {
    position: absolute;
    width: 0;
    height: 0;
}

.custom-left-menu.open .menu-mask {
    width: 100%;
    height: 100%;
}

.custom-left-menu .menu-body {
    z-index: 1;
    padding-top: 160px;
    flex-grow: 1;
    width: 100%;
    max-width: 276px;
    background-color: #E25971;
    box-shadow: 6px 0 16px -8px rgb(0 0 0 / 8%), 9px 0 28px 0 rgb(0 0 0 / 5%), 12px 0 48px 16px rgb(0 0 0 / 3%);
}

.custom-left-menu.open .menu-body {

}

#root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.hidetext {
    margin-top: 20px;
}

.hidetext, .hidetext * {
    color: rgb(87, 87, 87);
    font-family: 'Montserrat-Regular' !important;
    font-size: 10px;
}

.maxheight150 {
    max-height: 150px;
    overflow: auto;
    padding-right: 5px;
}

/* PTR2 START */

#ptr-container, #ptr-parent {
    overflow: unset !important;
    padding-bottom: 24px;
}

img {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

/* PTR2 END */

/* Swiper Start */
.swiper_block {
    height: 300px;
    max-width: 600px;
    margin: auto;
}

.swiper {
    width: 100%;
    height: 100%;
}
  
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
  
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.certificates_title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: "Montserrat-Medium" !important;
    text-align: center;
}
/* Swiper End */

.doctors_dates_content .spec_item .spec_table {
    margin-top: 20px;
    width: 100%;
}

.doctors_dates_content .spec_item .spec_table tr > td:first-child {
    width: 100px;
}

.consults_items_service_column {
    display: flex;
    flex-direction: column;
}

.consults_items_service {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.consults_item_service {
    width: calc((100% - 10px)/4);
    border-radius: 20px;
    background: rgba(255, 255, 255, .95);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    overflow: hidden;
    transition: .2s;
    cursor: pointer;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    margin: 10px;
}

.consults_item_service:hover {
    box-shadow: 0px 0px 10px 5px rgb(34 60 80 / 15%);
}

.consults_item_service img {
    max-width: 100%;
}

.consults_item_service_theme {
    font-family: 'Manrope-Bold' !important;
    color: #ffffff;
    border-radius: 0;
    background: rgb(233,114,130);
    background: url(./img/paw.svg), linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 100%);
    background-size: cover;
    box-shadow: 0px 0px 15px 10px rgb(0 0 0 / 20%);
    min-height: 48px;
    padding: 0 24px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1300px) {

    .consults_item_service {
        width: calc((100% - 10px)/3);
    }
}

@media screen and (max-width: 1000px) {

    .consults_item_service {
        width: calc((100% - 10px)/2);
    }
}

@media screen and (max-width: 700px) {

    .consults_item_service {
        width: calc(100% - 10px);
    }
}

.recordlocalcol {
    padding: 20px;
}

.recordlocalcol_title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    font-family: "Montserrat-SemiBold" !important;
}

.information_conslt {
    font-family: 'Montserrat-SemiBold' !important;
}

.calendar_title_text {
    padding: 15px 20px 0 20px;
    text-align: center;
    font-family: "Montserrat-SemiBold" !important;
    font-size: 16px;
    border-top: #EA6676 3px solid;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
}

.platform_rate_title {
    font-family: 'Montserrat-Medium' !important;
    font-size: 16px;
    margin-bottom: 0;
}